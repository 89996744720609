<template>
  <div>
   
      <h3>{{ greeting }}</h3>
        <div>
    <a-input
      v-model:value="address"
      placeholder="请输入地址"
      maxlength="80"
      allow-clear
      style="width: 330px; position: relative;"
    />
    <span
      v-if="address"
      @click="clearAddress"
      class="clear-btn"
      style="cursor: pointer; position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
    >
      &times;
    </span>
    <a-button type="primary" @click="search" style="margin-left:10px">查询</a-button>
  </div>


  <aTable :columns="columns" :data-source="results" :pagination="true" style="margin-top:5px"/>
  <!-- <aPagination
    :current="currentPage"
    :total="totalResults"
    pageSize=10
    @change="changePage"
  /> -->


      
   
    <!-- <a-table :dataSource="results" :pagination="false"> -->
      <!-- <a-table-column title="序号" :dataIndex="index" />
      <a-table-column title="时间" :dataIndex="use_time" />
      <a-table-column title="哈希" :dataIndex="t_id" />
      <a-table-column title="扣次" :dataIndex="kouci" /> -->
    <!-- </a-table> -->
    <!-- <a-pagination
      :current="currentPage"
      :total="totalResults"
      :pageSize="resultsPerPage"
      @change="changePage"
    /> -->
  </div>
</template>

<script>
import { Button, Input, Table, message } from 'ant-design-vue';

export default {
  components: {
    aInput: Input,
    aButton: Button,
    aTable: Table,

  },
  data() {
    return {
      address: '',
      results: [],
      currentPage: 1,
      resultsPerPage: 10,
      totalResults: 0,
      greeting: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'xuhao',
          key: 'xuhao',
           width: 50,
          
        },
        {
          title: '时间',
          dataIndex: 'use_time',
          key: 'use_time',
          width: 150,
        },
        {
          title: '哈希',
          dataIndex: 't_id',
          key: 't_id',
          ellipsis: true,
        },
        {
          title: '扣次',
          dataIndex: 'kouci',
          key: 'kouci',
          ellipsis: true,
        },
      ],
    };
  },
  methods: {
    clearAddress() {
      this.address = '';
    },
    search() {
      const regex = /^[a-zA-Z0-9]{34}$/;
      if (!regex.test(this.address)) {
        message.error('请输入正确的地址');
        return;
      }

      // 发送请求到后端 API，添加页码参数
      fetch(`/api/search?address=${encodeURIComponent(this.address)}&page=${this.currentPage}`)
        .then(response => response.json())
        .then(data => {
          this.results = data.data.map((item, index) => ({
            ...item,
            xuhao:index+1,
          
            use_time: new Date(item.use_time).toLocaleString('en-US', {
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }).replace(',', ''),
            kouci: item.xiaohao < 70000 ? 1 : 2, // 根据 xiaohao 的值决定扣次
          }));
          this.totalResults = data.total; // 更新总数
          this.resultsPerPage=10;
          this.greeting = '剩余次数:'+data.cishu;
          console.log(this.greeting);
         
        })
        .catch(error => {
          console.error('请求失败:', error);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.search(); // 重新搜索以获取新页的数据
    },
    setGreeting() {
      const hour = new Date().getHours();
      if (hour < 12) {
        this.greeting = '早上好';
      } else if (hour < 18) {
        this.greeting = '中午好';
      } else {
        this.greeting = '晚上好';
      }
      
    },
  },
  mounted() {
    this.setGreeting();
  },
};
</script>

<style scoped>
.clear-btn {
  color: #999;
}
.clear-btn:hover {
  color: #333;
}
</style>